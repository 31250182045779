<script setup lang="ts">
const props = defineProps<{
  images: string[]
  index: number
}>()

const visible = defineModel<boolean>()

function onHide() {
  visible.value = false
}
</script>

<template>
  <VueEasyLightbox
    :visible="visible"
    :imgs="props.images"
    :index="index"
    @hide="onHide"
    rotateDisabled
  />
</template>
