export function useLightbox() {
  const visible = ref(false)
  const index = ref(0)

  function showImg(_index: number) {
    index.value = _index
    visible.value = true
  }

  return {
    visible,
    index,
    showImg,
  }
}
